// DFields/ItemSelector

<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="800px">
		<v-card dark>
			<v-card-title primary-title class="title yellow--text">
				Title
			</v-card-title>
			<v-card-text style="height: 300px;">
			<v-row v-for="(item, idx) in items" :key="idx">
				<v-btn dark block tile color="indigo" @click="onSelection(item.dtable)">{{item.dtable}}</v-btn>
			</v-row>
    <!--<v-list dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item v-for="(item, i) in items" :key="i">
          <v-list-item-content>
            <v-list-item-title v-text="item.dtable" @click="onSelection(item.dtable)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>-->
			</v-card-text>
			<v-card-actions>
          		<v-btn text color="orange" @click="onCancel">Abbrechen</v-btn>
			</v-card-actions>
		</v-card>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
	</v-dialog>          
</template>

<script>
	//import X from './x'
	import { mapGetters } from 'vuex'
	export default {
	  	name: 'ItemSelector',
		components: {},
	  	props: {
	  		model: {
	  			type: Object,
	  			default () {
	  				return {}
	  			}
	  		},
	  	},
		data() {
			return {
				dialog: false,
				loading: false,
				selectedItem: null,
            }
        },
		computed: {
			...mapGetters({
				items: 'dfields/getDTables',
			}),
		},
		methods: {
			fetchData () {
				this.loading = true
				self = this
				this.$store.dispatch('dfields/getDTables')
				.then((response) => {
					//console.log(this.items)
					//this.sortData()
					self.loading = false
				})
				.catch((error) => {
					console.log(error)
					self.loading = false
				});
			},
			closeDialog(event, payload = null) {
				this.dialog = false
    			this.loading = false
	      		this.$emit(event, payload)
			},
			onSelection(selectedItemName) {
	      		this.closeDialog('selector-submitted', selectedItemName)
			},
			onCancel() {
	      		this.closeDialog('selector-cancelled')
			},
		},
		mounted() {
		},
	  	watch: {
		    model (newValue) {
			    if(newValue && typeof newValue === 'object' && Object.keys(newValue).length !== 0) {
			    	this.fetchData()
					this.dialog = true
			    }
		    },
			/*$props: {
				handler() {
					console.log(this.model)
				},
				deep: true,
				immediate: true,
			},*/
		    /*model: function () {
				immediate: true, 
    			handler (newValue, oldValue) {
						console.log(model)
					if(newValue && typeof newValue === 'object' && Object.keys(newValue).length !== 0) {
							console.log(model)
						if(model.dialog) {
							console.log(model)
							this.dialog = true
							this.loading = true
							setTimeout(() => {
								this.loading = false
							}, 3000)
						}
					}
				}
			},*/
			/*(newValue, oldValue) => {
		    },*/
		},
    }
</script>

<style>
</style>